

































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import { WechatSendMessageGroupService } from '@/services/wechat-send-messages-group-service';
import creatGroup from '@/views/massmessaging/massageAdd/creatGroup.vue';
import { massSet } from '@/services/massage-service';
@Component({
  components: {
    creatGroup
  }
})
export default class groupSet extends Vue {
  [x: string]: any;
  public form: any = {
    pages: 1,
    size: 10,
    current: 1,
    total: 0
  };
  public title: String = '创建图文分类';
  public datacreateTime: any[] = [];
  private tableData: any[] = [];
  public applications: any[] = [];
  public type: String = '';
  public loading: Boolean = true;
  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;
  @Inject(WechatSendMessageGroupService) private WechatSendMessageGroupService!: WechatSendMessageGroupService;
  @Inject(massSet) private massSet!: massSet;
  public async created(): Promise<void> {
    await this.applicationsList();
    await this.getList();
  }

  public search() {
    this.form.current = 1;
    if (this.datacreateTime && this.datacreateTime.length > 0) {
      this.form.createTimeStart = this.datacreateTime[0];
      this.form.createTimeEnd = this.datacreateTime[1];
    } else {
      this.form.createTimeStart = "";
      this.form.createTimeEnd = "";
    }
    this.getList()
  }
  // 重置
  public reset() {
    this.form = {
      size: this.form.size,
      // current: this.form.current,
      current: 1,
    };
    this.datacreateTime = [];
    this.getList();
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.loading = true;
    this.form.pageSize = Number(this.form.size);
    this.form.currentPage = Number(this.form.current);
    this.form.createTimeStart = this.form.createTimeStart;
    this.form.createTimeEnd = this.form.createTimeEnd;
    this.form.groupSetName = this.form.title;
    // this.form = {
    //   pageSize: Number(this.form.size),
    //   currentPage: Number(this.form.pages),
    //   'channelId': this.form.channelId,
    //   'createTimeStart': this.form.startTime,
    //   'createTimeEnd': this.form.endTime,
    //   'groupSetName': this.form.title
    // }
    const res = await this.massSet.getGroupSet(this.form);
    let data: any = res.records
    this.formData(data)
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.size = res.size;
    this.form.current = res.current;
    this.loading = false;
  }
  //处理专栏(这里是应用)id对应的name  
  public async formData(res: any) {
    let data = res
    this.tableData = data
  }
  //获取专栏(这里是应用)
  public async applicationsList(): Promise<void> {
    const res = await this.wechatImageTextService.applicationsList();
    this.applications = res
  }

  //删除列表
  private async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    }
    try {
      await this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.massSet.deleGroupSet(param);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //得到子组件的值

  //新增 创建图文
  public handAdd(): void {
    this.$router.push({
      path: '/greatGroup',
      query: {
        title: '创建群发分组',
        type: '0'
      }
    });
  }
  //修改
  public handUpdate(row: any): void {
    console.log(row)
    this.$router.push({
      path: '/greatGroup',
      query: {
        title: '修改群发分组',
        type: '1',
        rowList: row
      }
    });
  }
  private mounted() {
    document.onkeydown = (e) => {
      let key = (window.event as any).keyCode;
      if (key == 13) {
        this.search()
      }
    }
  }
}
